import config from "../../config/config";

const URLS = {
  Instagram: "https://instagram.com/wecheck_ltd?igshid=YmMyMTA2M2Y=",
  Facebook: "https://www.facebook.com/WeCheck.4U",
  internalLogin: "/login",
  Blog: "https://blog.wecheck.co.il/",
  QNA: "/qna",
  Contact: "/contact",
  Doublecheck: "/doublecheck",
  Newsletter: "/rent-ai",
  Chat: "/chat",
  Safecheck: "/safecheck",
  AllInAdvance: "/allinadvance",
  RentAgreement: "/rent-agreement",
  WeCheckPay: "/pay",
  EquityExtractionMort: "/equity-extraction-mortgage",
  AdditionalMort: "/additionalmort",
  PremiumMort: "/premiummort",
  CompleteMort: "/completemort",
  ReducedMort: "/reducedmort",
  Tennant: "/tennant",
  Mortgage: "/mortgage",
  Testimonials: "/testimonials",
  MortTestimonials: "/mortinfo/morttestimonials",
  MortCalc: "/mortinfo/mortcalc",
  MortQNA: "/mortinfo/qna",
  MortSite: "/mortsite",
  MortInfo: "/mortinfo",
  MortPersonalArea: "/mortinfo/personalarea",
  Acce: "/accessibility",
  MortLandingPage: "/mortgage-contact",
  Rent: "/rent",
  Mort: "/mort",
  FURL: "/furl/:token",
  SURL: "/surl/:token",
  YAD2Agent: "/yad2/agent",
  YAD2Owner: "/yad2/owner",
  Login: config.dashboardAddr + "/login",
  AgentLogin: config.dashboardAddr + "/login?agentlogin",
  MortgageQue: config.mortDashboardAddr + "/quemortgage",
  Legal: "https://dashboard.wecheck.co.il/furl/legaldoc",
  IntroToPersonalAreas: "/auth/intro/personal-areas",
};

export { URLS };
