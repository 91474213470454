import React from "react";
import AnchorTagWithQueryParams from "../../../anchor-tag-with-params";

import useHeader from "../../hooks/useHeader";
import LinkContainer from "./components/link-container";

import "./index.scss";

export default function HeaderLink({ link, refs, onNavClick, isNavOpen, isNewSiteActive, isMortPersonalAreaActive }) {
    const { isTopOfPage, isRentSiteMainPage } = useHeader();

    const isRentSiteMainPageTop = isTopOfPage && isRentSiteMainPage;
    const displayWhiteText = isRentSiteMainPageTop && !isNavOpen;

    return link.external ? (
        <AnchorTagWithQueryParams
            className={`header-link ${link.bold ? "text-bold" : ""} ${displayWhiteText ? "white-text" : ""
                }`}
            href={link.link}
            text={link.text}
        />
    ) : (
        <LinkContainer
            isNewSiteActive={isNewSiteActive}
            isMortPersonalAreaActive={isMortPersonalAreaActive}
            isNavOpen={isNavOpen}
            link={link}
            refs={refs}
            onNavClick={onNavClick}
            displayWhiteText={displayWhiteText}
        />
    );
};