import { URLS } from "../../shared/urls";
import Utils from "../../shared/utils";
import ReactPixel from "react-facebook-pixel";
import { reportCoversion } from "../../../helpers/google-ads";
import { LOGIN_TYPE } from "../../../redux/consts";
import config from "../../../config/config";

const MORTGAGE_EVENT_CATEGORY = "תפריט עליון משכנתאות";
const RENT_EVENT_CATEGORY = "תפריט עליון השכרה";
const CLICK_ACTION = "לחיצה";

const MAIN_PAGE_EVENT_CATEGORY = "עמוד ראשי";

export const links = {
  mortgage: [
    {
      text: "לבדיקת זכאות משכנתא משלימה",
      link: URLS.MortgageQue,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "לבדיקת זכאות משכנתא משלימה",
      external: true,
      bold: true,
    },
    {
      text: "מחשבון משכנתא משלימה",
      link: URLS.MortCalc,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד מחשבון משכנתא משלימה",
    },
    {
      text: "ההלוואות שלנו",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "ההלוואות שלנו",
      scrollTo: true,
      ref: "mortLoans",
    },
    {
      text: "מי אנחנו",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "מי אנחנו",
      scrollTo: true,
      ref: "mortWhoWeAre",
    },
    {
      text: "מספרים עלינו",
      link: URLS.MortTestimonials,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "מספרים עלינו",
    },
    {
      text: "שאלות ותשובות",
      link: URLS.MortQNA,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד שאלות ותשובות",
    },
    {
      text: "צור קשר",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "צור קשר",
      scrollTo: true,
      ref: "mortContact",
    },
  ],
  rent: [
    {
      text: "מי אנחנו",
      link: `${config.HOMEPAGE_URL}#WhoAreWe`,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "מי אנחנו",
      external: true,
      ref: "whoAreWe",
    },
    {
      text: "המוצרים שלנו",
      link: config.HOMEPAGE_URL,
      category: MAIN_PAGE_EVENT_CATEGORY,
      action: `Header - המוצרים שלנו`,
      label: "המוצרים שלנו",
      ref: "ourServices",
      hasTooltip: true,
      external: true,
      links: [
        {
          label: "דאבל צ'ק",
          to: `${config.HOMEPAGE_URL}${URLS.Doublecheck}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - דאבל צ'ק`,
          external: true,
        },
        {
          label: "צ'ק בטוח",
          to: `${config.HOMEPAGE_URL}${URLS.Safecheck}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - צ'ק בטוח`,
          external: true,
        },
        {
          label: "הכל מראש",
          to: `${config.HOMEPAGE_URL}${URLS.AllInAdvance}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - הכל מראש`,
          external: true,
        },
        {
          label: "חוזה שכירות",
          to: `${config.HOMEPAGE_URL}${URLS.RentAgreement}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - חוזה שכירות`,
          external: true,
        },
        {
          label: "WeCheckPay",
          to: `${config.HOMEPAGE_URL}${URLS.WeCheckPay}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - WeCheckPay`,
          external: true,
        },
        {
          label: "RentAI",
          to: URLS.Newsletter,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - המוצרים שלנו - rentAI`,
          external: false,
        },
      ],
    },
    {
      text: "השותפים שלנו",
      link: `${config.HOMEPAGE_URL}#OurPartners`,
      external: true,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "השותפים שלנו",
      // scrollTo: true,
      ref: "ourPartners",
    },
    {
      text: "שאלות ותשובות",
      link: URLS.QNA,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד שאלות ותשובות",
    },
    {
      text: "צור קשר",
      link: URLS.Contact,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד צור קשר",
    },
    {
      text: "בלוג",
      link: URLS.Blog,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "בלוג",
    },
    {
      text: "משכנתא WeCheck",
      link: null,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד משכנתאות",
      hasTooltip: true,
      links: [
        {
          label: "משכנתא משלימה",
          to: URLS.AdditionalMort,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא משלימה`,
          external: true,
        },
        {
          label: "משכנתא מאחדת",
          to: URLS.CompleteMort,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא מאוחדת`,
          external: true,
        },
        {
          label: "משכנתא במסלול מופחת",
          to: URLS.ReducedMort,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא במסלול מופחת`,
          external: true,
        },
        {
          label: "משכנתא פרמיום",
          to: URLS.PremiumMort,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא פרמיום`,
          external: true,
          isPremium: true,
        },
        {
          label: "חילוץ הון עצמי",
          to: `/products${URLS.EquityExtractionMort}`,
          category: MAIN_PAGE_EVENT_CATEGORY,
          action: `Header - משכנתא חילוץ הון עצמי`,
          external: true,
        },
        {
          label: "כניסה לאיזור האישי",
          to: URLS.MortPersonalArea,
          personalArea: true,
        },
      ],
    },
  ],
  mortgageOld: [
    {
      text: "לבדיקת זכאות משכנתא משלימה",
      link: URLS.MortgageQue,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "לבדיקת זכאות משכנתא משלימה",
      external: true,
      bold: true,
    },
    {
      text: "מחשבון משכנתא משלימה",
      link: URLS.MortCalc,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "עמוד מחשבון משכנתא משלימה",
    },
    {
      text: "מי אנחנו",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "מי אנחנו",
      scrollTo: true,
      ref: "mortWhoWeAreOld",
    },
    {
      text: "השותפים שלנו",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "השותפים שלנו",
      scrollTo: true,
      ref: "mortPartnersOld",
    },
    {
      text: "צור קשר",
      link: URLS.MortInfo,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "צור קשר",
      scrollTo: true,
      ref: "mortContact",
    },
  ],
};

export const buttons = {
  mortgage: [
    {
      text: "כניסה ליועצים",
      color: "red",
      link: URLS.MortgageQue + "?agent=1",
      external: true,
      category: MORTGAGE_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "כניסת יועץ",
    },
    {
      text: "איזור אישי",
      color: "blue",
      link: URLS.MortPersonalArea,
      personalArea: true,
    },
  ],
  rent: [
    {
      text: "אזור אישי",
      color: "dark-blue",
      link: URLS.IntroToPersonalAreas,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "כניסת לאזור אישי",
      external: true,
    },
  ],
  rentAI: [
    {
      text: "אזור אישי",
      color: "dark-blue",
      overRideDefaultQueryParams: true,
      link: `${URLS.IntroToPersonalAreas}?utm_source=RentAI&utm_medium=personal-areas&utm_campaign=shai`,
      category: RENT_EVENT_CATEGORY,
      action: CLICK_ACTION,
      label: "כניסת לאזור אישי",
      external: true,
    },
  ],
};

export const logo = {
  category: "תפריט עליון לוגו",
  action: "לחיצה על לוגו WeCheck",
  label: "לוגו וויצ'ק - Header",
};
