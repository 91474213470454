import React from "react";
import Utils from "../../shared/utils";
import HeaderButton from "./components/header-button";
import { Hamburger } from "./components/hamburger";
import { SocialIcons } from "./components/social-icons";
import { buttons, links, logo } from "./consts";
import useHeader from "./hooks/useHeader";
import useIsMobile from "../../hooks/useIsMobile";
import { URLS } from "../../shared/urls";
import "./index.scss";
import HeaderLink from "./components/header-link";
import { Logo } from "../logo";
import { useSelector } from "react-redux";
import PersonalAreaHeader from "../personal-area-header";
import config from "../../../config/config";
import AnchorTagWithQueryParams from "../anchor-tag-with-params";

const HeaderLogo = ({ isNavOpen, customCategory }) => {
  const { isMortSite, isTopOfPage, isRentSiteMainPage, isMortLandingPage } =
    useHeader();
  const isRentSiteMainPageTop = isTopOfPage && isRentSiteMainPage;

  return (
    <AnchorTagWithQueryParams
      onClick={() =>
        Utils.gaEvent(
          customCategory ? customCategory : logo.category,
          logo.action,
          logo.label
        )
      }
      href={
        isMortSite || isMortLandingPage ? URLS.MortInfo : config.HOMEPAGE_URL
      }
      className="HeaderLogo"
    >
      <Logo
        isPromotion={false}
        color={isRentSiteMainPageTop && !isNavOpen ? "white" : "blue"}
      />
    </AnchorTagWithQueryParams>
  );
};

const LinksList = ({ refs, onNavClick, isNavOpen, setIsNavOpen }) => {
  const { isMortSite } = useHeader();

  const isNewMortSiteActive = useSelector(
    (state) => state.flags.flags.isNewMortSiteActive
  );

  const isMortPersonalAreaActive = useSelector(
    (state) => state.flags.flags.isMortPersonalAreaActive
  );

  const list = isMortSite
    ? isNewMortSiteActive
      ? links.mortgage
      : links.mortgageOld
    : links.rent;
  return list.map((link, index) => {
    return (
      <HeaderLink
        setIsNavOpen={setIsNavOpen}
        onNavClick={onNavClick}
        refs={refs}
        link={link}
        key={index}
        isNavOpen={isNavOpen}
        isNewSiteActive={isNewMortSiteActive}
        isMortPersonalAreaActive={isMortPersonalAreaActive}
      />
    );
  });
};

export const HeaderButtons = () => {
  const { isMortSite, isRentAISite } = useHeader();
  const isMortPersonalAreaActive = useSelector(
    (state) => state.flags.flags.isMortPersonalAreaActive
  );
  const list = isMortSite
    ? buttons.mortgage
    : isRentAISite
    ? buttons.rentAI
    : buttons.rent;

  return list.map((button, index) => {
    return (
      <HeaderButton
        key={index}
        text={button.text}
        color={button.color}
        link={button.link}
        external={button.external}
        category={button.category}
        action={button.action}
        label={button.label}
        func={button.func}
        overRideDefaultQueryParams={button.overRideDefaultQueryParams}
        personalArea={button.personalArea}
        isMortPersonalAreaActive={isMortPersonalAreaActive}
      />
    );
  });
};

const Header = ({
  refs,
  customCategory,
  hidden,
  isNewsletter,
  isMortPersonalArea,
}) => {
  const {
    isNavOpen,
    setIsNavOpen,
    onNavClick,
    isRentSiteMainPage,
    isTopOfPage,
    isMortLandingPage,
  } = useHeader();
  const isMobile = useIsMobile();

  return isMortPersonalArea ? (
    <PersonalAreaHeader />
  ) : (
    <header
      className={`Header ${isNavOpen ? "open" : ""} ${
        isRentSiteMainPage && isTopOfPage ? "transparent" : ""
      } ${isMortLandingPage ? "justify-center" : ""} ${
        isNewsletter ? "newsletter" : ""
      } ${hidden ? "slide-up" : ""}`}
    >
      <div className="top-part-container">
        {!isMortLandingPage && !isMortPersonalArea && (
          <Hamburger
            isOffset={isRentSiteMainPage && isTopOfPage}
            isOpen={isNavOpen}
            setIsOpen={setIsNavOpen}
          />
        )}
        <HeaderLogo customCategory={customCategory} isNavOpen={isNavOpen} />
      </div>
      <div className={`link-list-container ${isNavOpen ? "open" : ""}`}>
        {isMobile && <SocialIcons />}

        {!isMortLandingPage && (
          <>
            <div className="links-container">
              <LinksList
                setIsNavOpen={setIsNavOpen}
                isNavOpen={isNavOpen}
                onNavClick={onNavClick}
                refs={refs}
              />
            </div>
            <div className="buttons-container">
              <HeaderButtons />
            </div>
          </>
        )}
      </div>
    </header>
  );
};

export { Header };
