import React from "react";
import { Link } from "react-router-dom";
import Utils from "../../../../shared/utils";
import AnchorTagWithQueryParams from "../../../anchor-tag-with-params";
import "./index.scss";

export default function HeaderButton({
  external,
  text,
  color,
  link,
  category,
  action,
  label,
  func,
  overRideDefaultQueryParams,
  isMortPersonalAreaActive,
  personalArea,
}) {
  return external ? (
    <AnchorTagWithQueryParams
      onClick={
        func
          ? () => {
              func();
              Utils.gaEvent(category, action, label);
            }
          : () => Utils.gaEvent(category, action, label)
      }
      href={link}
      overRideDefaultQueryParams={overRideDefaultQueryParams}
      className="HeaderButton"
      isAgentLink={link.includes("?")}
    >
      <div className="icon-container">
        <img
          src={Utils.buildImgLink(`header/buttons/icon-${color}.svg`)}
          alt=""
        />
      </div>
      <p>{text}</p>
    </AnchorTagWithQueryParams>
  ) : personalArea && !isMortPersonalAreaActive ? null : (
    <Link
      onClick={
        func
          ? () => {
              func();
              Utils.gaEvent(category, action, label);
            }
          : () => Utils.gaEvent(category, action, label)
      }
      to={link}
      className="HeaderButton"
    >
      <div className="icon-container">
        <img
          src={Utils.buildImgLink(`header/buttons/icon-${color}.svg`)}
          alt=""
        />
      </div>
      <p>{text}</p>
    </Link>
  );
}
