import React from "react";
import Utils from "../../../../shared/utils";
import "./index.scss";

export const SocialIcons = () => {
	return (
		<div className = "social-icons-container" >
			<a href = "https://www.instagram.com/wecheck_ltd/" target = "_blank" rel = "noreferrer noopener" >
				<img src = {Utils.buildImgLink("header/social/instagram-icon.svg")} alt = "instagram icon" />
			</a >
			<a href = "https://www.facebook.com/WeCheck.4U/" target = "_blank" rel = "noreferrer noopener" >
				<img src = {Utils.buildImgLink("header/social/facebook-icon.svg")} alt = "facebook icon" />
			</a >
			<a href = "https://www.linkedin.com/company/72698846/admin/" target = "_blank" rel = "noreferrer noopener" >
				<img src = {Utils.buildImgLink("header/social/linkedin-icon.svg")} alt = "linkedin icon" />
			</a >
		</div >
	);
};
