import React from "react";
import "./index.scss";

export const Hamburger = ({ isOpen, setIsOpen, isOffset }) => {
  return (
    <button
      className="hamburger-container"
      onClick={() => setIsOpen((open) => !open)}
    >
      <svg
        className={`hamburger ${isOpen ? "open" : ""} ${
          isOffset && !isOpen ? "fill-white" : ""
        }`}
        viewBox="0 0 100 100"
        width="30"
        height="30"
      >
        <rect
          className="line top"
          width="80"
          height="10"
          x="10"
          y="25"
          rx="5"
        ></rect>
        <rect
          className="line middle"
          width="80"
          height="10"
          x="10"
          y="45"
          rx="5"
        ></rect>
        <rect
          className="line bottom"
          width="80"
          height="10"
          x="10"
          y="65"
          rx="5"
        ></rect>
      </svg>
    </button>
  );
};
