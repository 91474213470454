import React from "react";

import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";

import "./index.scss";
import Utils from "../../../../../../shared/utils";

import AnchorTagWithQueryParams from "../../../../../anchor-tag-with-params";

export default function DesktopLinksList({
  link,
  isNewSiteActive,
  isMortPersonalAreaActive,
}) {
  return (
    <ReactTooltip
      id={link.label}
      place="bottom"
      type="light"
      effect="solid"
      className="tooltip-container"
      delayHide={100}
    >
      <div className="links-tooltip-container">
        {link.links.map((innerLink, index) => {
          return innerLink.isPremium &&
            !isNewSiteActive ? null : innerLink.personalArea &&
            !isMortPersonalAreaActive ? null : innerLink.external ? (
            <AnchorTagWithQueryParams
              key={index}
              className={`header-link ${link.bold ? "text-bold" : ""}`}
              href={innerLink.to}
              text={innerLink.label}
            />
          ) : (
            <Link
              onClick={() =>
                Utils.gaEvent(
                  innerLink.category,
                  innerLink.action,
                  innerLink.label
                )
              }
              to={innerLink.to}
              key={index}
            >
              {innerLink.label}
            </Link>
          );
        })}
      </div>
    </ReactTooltip>
  );
}
